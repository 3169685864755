import React from 'react';
import images from 'src/static/assets';

const { footerLogo } = images;

import css from './Footer.module.scss';

const Footer = () => {
  return (
    <footer>
      <div className={css.footerWrapper}>
        <div className={css.logoWrapper}>
          <a href="https://www.n12.co.il">
            <img src={footerLogo} alt="logo" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
