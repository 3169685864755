import React, { createContext, useReducer, useContext } from 'react';
import _ from 'lodash';

import { replaceFields, FIELDS_MAP } from '@api/utils/replace-fields';
import { getPeopleData } from 'src/api';

export type PersonInfo = Record<keyof typeof FIELDS_MAP, string | number>;

export enum SortOptions {
  AZ = 'az',
  RECENT = 'recent',
  RANDOM = 'random',
}

export type AdvancedFilters = {
  search: string;
  place: string;
  role: string;
  unit: string;
};

interface IState {
  settings: {
    subTitle: string;
    mainTitle: string;
  };
  people: any[];
  hostages: any[];
  returnedHostages: any[];
  earlierKidnepped: any[];
  selectedPerson: null | PersonInfo;
  isPersonModalVisible: boolean;
  isInstagramModalVisible: boolean;
  sorting: SortOptions;
  currentPage: number;
  filters: AdvancedFilters;
  isFetching: boolean;
  normalizedPeopleData: PersonInfo[];
}

type Action =
  | {
      type: 'SET_DATA';
      payload: Pick<IState, 'settings' | 'people' | 'hostages' | 'returnedHostages' | 'earlierKidnepped'>;
    }
  | { type: 'SET_SELECTED_PERSON'; payload: IState['selectedPerson'] }
  | { type: 'SET_IS_PERSON_MODAL_VISIBLE'; payload: IState['isPersonModalVisible'] }
  | { type: 'SET_IS_INSTAGRAM_MODAL_VISIBLE'; payload: IState['isInstagramModalVisible'] }
  | { type: 'SET_SORTING'; payload: IState['sorting'] }
  | { type: 'SET_FILTERS'; payload: Partial<IState['filters']> }
  | { type: 'SET_CURRENT_PAGE'; payload: IState['currentPage'] }
  | { type: 'SET_IS_FETCHING'; payload: boolean };

const StateContext = createContext<IState | null>(null);
const DispatchContext = createContext<React.Dispatch<Action> | null>(null);

const initialState: IState = {
  settings: {
    subTitle: '',
    mainTitle: '',
  },
  isPersonModalVisible: false,
  isInstagramModalVisible: false,
  people: [],
  hostages: [],
  returnedHostages: [],
  earlierKidnepped: [],
  sorting: SortOptions.RANDOM,
  selectedPerson: null,
  currentPage: 1,
  isFetching: true,
  filters: {
    search: '',
    place: '',
    role: '',
    unit: '',
  },
  normalizedPeopleData: [],
};

function reducer(state: IState, action: Action): IState {
  switch (action.type) {
    case 'SET_DATA':
      return {
        ...state,
        settings: action.payload.settings,
        people: action.payload.people,
        hostages: action.payload.hostages,
        returnedHostages: action.payload.returnedHostages,
        earlierKidnepped: action.payload.earlierKidnepped,
        normalizedPeopleData: replaceFields(action.payload.people),
      };

    case 'SET_SELECTED_PERSON':
      return {
        ...state,
        selectedPerson: action.payload,
      };
    case 'SET_IS_PERSON_MODAL_VISIBLE':
      return {
        ...state,
        isPersonModalVisible: action.payload,
      };
    case 'SET_IS_INSTAGRAM_MODAL_VISIBLE':
      return {
        ...state,
        isInstagramModalVisible: action.payload,
      };
    case 'SET_SORTING':
      return {
        ...state,
        sorting: action.payload,
      };
    case 'SET_CURRENT_PAGE':
      return {
        ...state,
        currentPage: action.payload,
      };
    case 'SET_IS_FETCHING':
      return {
        ...state,
        isFetching: action.payload,
      };
    case 'SET_FILTERS':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };

    default:
      return state;
  }
}

export function AppContextProvider(props: React.PropsWithChildren) {
  const [state, dispatch] = useReducer(reducer, initialState);

  React.useEffect(() => {
    const fetchData = async () => {
      const peopleData = await getPeopleData();

      if (peopleData) {
        const EXEMPTION_GROUP = '999';
        const exemptionGroup = _.values(
          _.groupBy(
            _.filter(peopleData.rows, (p) => p.status === '1' && p.group === EXEMPTION_GROUP),
            'group',
          ),
        );

        const otherHostages = _.shuffle(
          _.values(
            _.groupBy(
              _.filter(peopleData.rows, (p) => p.status === '1' && p.group !== EXEMPTION_GROUP),
              'group',
            ),
          ),
        );

        dispatch({
          type: 'SET_DATA',
          payload: {
            settings: {
              ...peopleData.settings,
            },
            people: peopleData.rows,
            hostages: [...otherHostages, ...exemptionGroup],
            returnedHostages: _.shuffle(
              _.values(
                _.groupBy(
                  _.filter(peopleData.rows, (p) => p.status === '2'),
                  'group',
                ),
              ),
            ),
            earlierKidnepped: _.filter(peopleData.rows, (p) => p.status === '3') || [],
          },
        });
        dispatch({
          type: 'SET_IS_FETCHING',
          payload: false,
        });
      }
    };

    fetchData();
  }, []);

  if (!state.people?.length) {
    // return null;
  }

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{props.children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);

  if (!context) {
    throw new Error('useAppState must be used within a AppContextProvider');
  }

  return context as { [K in keyof IState]: NonNullable<IState[K]> };
}

export function useAppDispatch() {
  const dispatch = useContext(DispatchContext);

  if (!dispatch) {
    throw new Error('useAppDispatch must be used within a AppContextProvider');
  }

  return dispatch;
}
