import React from 'react';
import cn from 'classnames';
import { PersonInfo } from 'src/Context';

import css from './Persons.module.scss';

interface Props {
  showItems: PersonInfo[];
  isAnimation: boolean;
  onPersonCandleBtnClick: (p: PersonInfo) => void;
  isEarlierKidnepped?: boolean;
}

const Persons = ({ showItems, isAnimation, onPersonCandleBtnClick, isEarlierKidnepped = false }: Props) => {
  const clickOnPerson = (p: PersonInfo) => {
    onPersonCandleBtnClick(p);
  };

  const renderItems = () => {
    return showItems.map((i, index: number) => {
      return (
        <div
          className={cn(css.gridItem, isAnimation && css.animation, isEarlierKidnepped && css.isEarlierKidnepped)}
          key={`${i.b}${i.c}${index}`}
        >
          <div className={css.photo}>{i.j && <img src={`${i.j}`} alt={`${i.b || ''} ${i.c || ''}`.trim()} />}</div>
          <div className={css.infoWrap}>
            <div className={css.info}>
              <div className={css.top}>
                <div className={css.texts}>
                  <div className={css.name}>
                    {i.b} {i.c}
                    {i.d && (i.b || i.c) && `, `} {i.d}
                  </div>
                  {isEarlierKidnepped && <div className={css.ageAndCity}>{i.i}</div>}
                  <div className={css.description}>
                    {(i.l as string).length > 120 ? `${(i.l as string).slice(0, 120)}...` : i.l}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button type="button" className={css.button} onClick={() => clickOnPerson(i)}>
            <span>{`קרא עוד >`}</span>
          </button>
        </div>
      );
    });
  };

  return <>{renderItems()}</>;
};

export default Persons;
