import desktopBg from 'src/static/assets/desktop-bg.jpg';
import mobileBg from 'src/static/assets/mobile-bg.jpg';
import searchIconSrc from 'src/static/assets/search-icon.svg';
import siteLogo from 'src/static/assets/site-logo.svg';
import facebook from 'src/static/assets/facebook.svg';
import twitter from 'src/static/assets/twitter.svg';
import whatsapp from 'src/static/assets/whatsapp.svg';
import toggle from 'src/static/assets/toggle.svg';
import shareToggle from 'src/static/assets/share-toggle.svg';
import ribbonIcon from 'src/static/assets/ribbon-icon.svg';
import intro from 'src/static/assets/intro.svg';
import instagramPopupLogo from 'src/static/assets/instagramPopupLogo.svg';
import instagramYellow from 'src/static/assets/instagram.svg';
import logoN12 from 'src/static/assets/logo-n12.svg';
import logoN12Mini from 'src/static/assets/logo-n12-mini.svg';
import footerLogo from 'src/static/assets/footer-logo.svg';

const images = {
  desktopBg,
  mobileBg,
  searchIconSrc,
  siteLogo,
  facebook,
  twitter,
  whatsapp,
  toggle,
  shareToggle,
  ribbonIcon,
  intro,
  instagramPopupLogo,
  instagramYellow,
  logoN12,
  logoN12Mini,
  footerLogo,
};

export default images;
