import React, { FC } from 'react';
import cn from 'classnames';
import { useResponsive } from 'src/hooks/responsive';
import _ from 'lodash';

import { useAppDispatch, useAppState } from 'src/Context';
import { analytics } from 'src/utils';

import css from './PersonModal.module.scss';

interface Props {
  className?: string;
}

const PersonModal: FC<Props> = ({ className = '' }) => {
  const { selectedPerson, isPersonModalVisible } = useAppState();
  const dispatch = useAppDispatch();
  const [isMobile] = useResponsive('MOBILE');

  React.useEffect(() => {
    if (selectedPerson && isPersonModalVisible) {
      analytics.gtag.event('more info', {
        CUSTOM_PARAMETER: `${selectedPerson.b} ${selectedPerson.c}`,
      });
    }
  }, [selectedPerson, isPersonModalVisible]);

  const onCloseBtnClick = () => {
    dispatch({ type: 'SET_SELECTED_PERSON', payload: null });
    dispatch({ type: 'SET_IS_PERSON_MODAL_VISIBLE', payload: false });
  };

  return (
    <div className={cn(css.personModal, className, !isPersonModalVisible && css.hidden)}>
      <div className={css.bg} onClick={onCloseBtnClick} />
      <div className={css.modalContent}>
        <button className={css['close-btn']} onClick={onCloseBtnClick}>
          <span />
          <span />
        </button>

        {selectedPerson?.video ? (
          <div
            className={css.video}
            dangerouslySetInnerHTML={{
              __html: isMobile
                ? selectedPerson.video
                    .toString()
                    .replace('width="632"', 'width="272"')
                    .replace('height="473"', 'height="145"')
                : (selectedPerson.video as string),
            }}
          />
        ) : (
          <div className={css.photo}>
            {selectedPerson?.j && <img src={selectedPerson?.j as string} alt={`${selectedPerson?.b}`} />}
          </div>
        )}

        <div className={css.title}>
          {selectedPerson?.b} {selectedPerson?.c}
        </div>

        {selectedPerson?.i && <div className={css.additionalInfo}>{selectedPerson.i}</div>}

        <div className={css.subtitle}> {selectedPerson?.l || ''}</div>
        {selectedPerson?.article && (
          <a
            href={selectedPerson.article.toString()}
            target="_blank"
            rel="noopener noreferrer"
            className={css.linkButton}
            onClick={() =>
              analytics.gtag.event('לכתבה', { CUSTOM_PARAMETER: `${selectedPerson.b} ${selectedPerson.c}` })
            }
          >
            לכתבה {'>'}
          </a>
        )}
      </div>
    </div>
  );
};

export default PersonModal;
