export const CAN_USE_DOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);
export const DEFAULT_IMAGE = 'https://img.mako.co.il/2023/10/15/placeholder-pic.png';

// import personsPhoto from 'src/static/assets/persons';
export const EARLIER_KIDNEPPED = {
  title: 'חטופים ברצועת עזה לפני ה-7.10.23',
  // people: [
  //   {
  //     id: '001',
  //     b: 'סגן הדר ',
  //     c: 'גולדין ז"ל',
  //     d: '',
  //     e: '',
  //     f: '',
  //     h: '',
  //     i: 'בן 21, אשחר',
  //     j: personsPhoto.person1,
  //     l: 'הדר גולדין נהרג בקרב רפיח במהלך "צוק איתן" ב-1.8.2014, בעת הפסקת אש. גופתו נחטפה',
  //     video: '',
  //     article: '',
  //     group: '',
  //     status: '3',
  //   },
  //   {
  //     id: '002',
  //     b: 'סמ"ר אורון ',
  //     c: 'שאול ז"ל',
  //     d: '',
  //     e: '',
  //     f: '',
  //     h: '',
  //     i: 'בן 21, פוריה',
  //     j: personsPhoto.person2,
  //     l: `אורון שאול נהרג בקרב שג'אעיה במהלך "צוק איתן" ב-20.7.2014. גופתו נחטפה`,
  //     video: '',
  //     article: '',
  //     group: '',
  //     status: '3',
  //   },
  //   {
  //     id: '003',
  //     b: 'אברה ',
  //     c: 'מנגיסטו',
  //     d: '',
  //     e: '',
  //     f: '',
  //     h: '',
  //     i: 'בן 37, אשקלון',
  //     j: personsPhoto.person3,
  //     l: 'אברה נחטף כשהיה בן 28, ב-7.9.2014, לאחר שחצה את הגבול לרצועת עזה',
  //     video: '',
  //     article: '',
  //     group: '',
  //     status: '3',
  //   },
  //   {
  //     id: '004',
  //     b: 'הישאם ',
  //     c: 'א-סייד',
  //     d: '',
  //     e: '',
  //     f: '',
  //     h: '',
  //     i: 'בן 35, חורה',
  //     j: personsPhoto.person4,
  //     l: 'הישאם, ישראלי בדואי תושב הדרום, נחטף כשחצה את הגבול לרצועת עזה באפריל 2015',
  //     video: '',
  //     article: '',
  //     group: '',
  //     status: '3',
  //   },
  // ],
};
