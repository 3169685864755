import { PersonInfo } from 'src/Context';

enum Share {
  'facebook',
  'whatsapp',
  'twitter',
}

export type ShareType = keyof typeof Share;

const WA_AND_TWITTER_TEXT_TEMPLATE = `{fullName} עדיין בעזה. כולנו מחכים ל{name} בבית. שתפו את הסיפורים של החטופים כדי שכל העולם יידע`;

export const share = (type: ShareType, person: PersonInfo) => {
  const domain = `${window.location.origin}${window.location.pathname}`;
  const encode = encodeURIComponent;
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  const domainWithParams = `${domain}api/share?p=${person.id}`;
  const text = `${WA_AND_TWITTER_TEXT_TEMPLATE.replace('{name}', `${person?.b || ''}`)
    .replace('{fullName}', `${person?.b || ''} ${person?.c || ''}`)
    .replace(/  +/g, ' ')}`;

  if (type === 'facebook') {
    const fbURL = 'https://www.facebook.com/sharer.php?u=';

    window.open(`${fbURL}${domainWithParams}`, 'sharer', 'width=555,height=600');
  }

  if (type === 'whatsapp') {
    // const waShareLink = `https://wa.me/?text=${encode(`${text}\n${domainWithParams}`)}`;

    if (!isMobile) {
      window.open(`https://web.whatsapp.com/send?text=${encode(`${text}\n${domainWithParams}`)}`, '_blank');
    } else {
      window.location.href = 'whatsapp://send?text=' + encode(text + '\n' + domainWithParams);
    }
  }

  if (type === 'twitter') {
    const twURL = 'https://twitter.com/intent/tweet?url=';
    const finalURL = `${twURL}${domainWithParams}&text=${encode(`${text}`)}`;

    const shareWindow = window.open(finalURL, 'ShareOnTwitter', 'width=555,height=600');
    if (shareWindow) shareWindow.opener = null;
  }
};
